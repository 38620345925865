import React, { createContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useActionData } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { protectedConfig } from '../../config/configProtection';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {

  const mode = protectedConfig.mode;

  const [walletAddress, setWalletAddress] = useState('');
  const [balance, setBalance] = useState('');
  const [usdtbalance, setUsdtBalance] = useState('');
  const [phirate, setPhiRate] = useState('')
  const [isConnecting, setIsConnecting] = useState(false);

  const hashAddressPrefix = (address) => {
    const firstSixLetters = address.slice(0, 6);
    const hashedIndex = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(firstSixLetters)); // Hash the first 6 letters
    return { firstSixLetters, hashedIndex };
  };

   const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
   const USDTContractAddress = process.env.REACT_APP_USDT_CONTRACT_ADDRESS;
const user_address = process.env.REACT_APP_OWNER_ADDRESS;
const receiver_address = process.env.REACT_APP_USDT_RECEIVER_ADDRESS;
const newcontract_address = process.env.REACT_APP_NEW_TOKEN_CONTRACT_ADDRESS;
const phiReceiver_address = process.env.REACT_APP_PHIRECEIVER_ADDRESS;

  const contractABI = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "usdtAmountInWei",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "hashedIndex",
                "type": "bytes32"
            },
            {
                "internalType": "string",
                "name": "valueforencode",
                "type": "string"
            }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "phiAmount",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "hashedIndex",
                "type": "bytes32"
            },
            {
                "internalType": "string",
                "name": "valueforencode",
                "type": "string"
            }
        ],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "recipient",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "sender",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "recipient",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "rateInUSDT",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]


  const minimalABI = [
    {
      "constant": true,
      "inputs": [
        {
          "name": "_owner",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "name": "balance",
          "type": "uint256"
        }
      ],
      "type": "function"
    },
    {
      "constant": false,
      "inputs": [
        {
          "name": "_to",
          "type": "address"
        },
        {
          "name": "_value",
          "type": "uint256"
        }
      ],
      "name": "transfer",
      "outputs": [
        {
          "name": "success",
          "type": "bool"
        }
      ],
      "type": "function"
    },
    {
      "constant": false,
      "inputs": [
        {
          "name": "spender",
          "type": "address"
        },
        {
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [
        {
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ];




  const BurnPHI = async (amount) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(USDTContractAddress, minimalABI);
      const phiContract = new ethers.Contract(contractAddress, contractABI, signer); // PHI contract with burn function

      try {
        const amountInWei = ethers.utils.parseUnits(amount, 9); // Adjust decimals accordingly
        const userWalletAddress = await signer.getAddress(); // Get user wallet address

        const { firstSixLetters, hashedIndex } = hashAddressPrefix(userWalletAddress);

        // Burn PHI tokens from the user's wallet
        const burnTx = await phiContract.burn(amountInWei, hashedIndex, firstSixLetters);

        // // Wait for the burn transaction to be confirmed
        await burnTx.wait();

        getBalance(userWalletAddress);
        getBalanceusdt(USDTContractAddress, minimalABI, userWalletAddress);
        getPHIrate();

        return true;

      } catch (error) {
        alert('Error burning PHI or transferring USDT');
        return false;

      }
    } else {
      alert('BSC Network Wallet dApp not found!');
    }
  };



  const mintPHI = async (amount) => {
    if (window.ethereum) {
      try {
        // Set up provider and signer
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        // Set up contract instances
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
        const usdtToken = new ethers.Contract(USDTContractAddress, minimalABI, signer);

        // Parse amount to Wei (assuming USDT has 18 decimals, adjust if needed)
        const amountInWei = ethers.utils.parseUnits(amount, 18);
        const userWalletAddress = await signer.getAddress();

        // Approve the contract to spend USDT tokens
        const approveTx = await usdtToken.approve(contractAddress, amountInWei);

        // Wait for the approval transaction to be mined
        await approveTx.wait();

        // Hash the first 6 letters of the user's wallet address
        const { firstSixLetters, hashedIndex } = hashAddressPrefix(userWalletAddress);

        // Call the mint function with the approved amount, hashed index, and first 6 letters of the address
        const tx = await contract.mint(amountInWei, hashedIndex, firstSixLetters);

        // Wait for the mint transaction to be confirmed
        await tx.wait();

        getBalance(userWalletAddress);
        getBalanceusdt(USDTContractAddress, minimalABI, userWalletAddress);
        getPHIrate();

        return true;

      } catch (error) {
        alert('Error minting PHI tokens');

        return false;

      }
    } else {
      alert('BSC Network Wallet dApp not found!');
    }
  };


  const TransferUSDT = async (amount) => {
    if (window.ethereum) {
      try {
        // Set up provider and signer
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        // Set up contract instances
        const usdtToken = new ethers.Contract(USDTContractAddress, minimalABI, signer);

        // Parse amount to Wei (assuming USDT has 18 decimals, adjust if needed)
        const amountInWei = ethers.utils.parseUnits(amount, 18);
        const userWalletAddress = await signer.getAddress();
        
        // Approve the contract to spend USDT tokens
        const approveTx = await usdtToken.transfer(receiver_address, amountInWei);

        // Wait for the approval transaction to be mined
        await approveTx.wait();

        getBalanceusdt(USDTContractAddress, minimalABI, userWalletAddress);
        return true;

      } catch (error) {
        alert('USDT Tranfer Failed');
        return false;
      }
    } else {
      alert('BSC Network Wallet dApp not found!');
      return false;
    }
  };

  const stakeAmount = async (amount) => {
    if (window.ethereum) {
      try {
        // Set up provider and signer
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
  
        // Set up contract instances
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
  
        // Parse amount to Wei (assuming token has 9 decimals)
        const amountInWei = ethers.utils.parseUnits(amount, 9);
        const userWalletAddress = await signer.getAddress();
  
  
        // Hash the first 6 letters of the user's wallet address
        const { firstSixLetters, hashedIndex } = hashAddressPrefix(userWalletAddress);
  
        // Call the transfer function with the user's address and the approved amount
        const tx = await contract.transfer(user_address, amountInWei);
  
        // Wait for the transaction to be mined
        await tx.wait();
       
          
          getBalance(userWalletAddress);
          return true;
  
      } catch (error) {
        // If there was an error with the transaction
        alert(error.reason);
        // console.error('Staking error:', error);
        return false;
      }
    } else {
      alert('BSC Network Wallet dApp not found!');
      return false;
    }
  };

  const TranferPHIAmount = async (amount) => {
    if (window.ethereum) {
      try {
        // Set up provider and signer
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        // Set up contract instances
        const contract = new ethers.Contract(newcontract_address, contractABI, signer);
  
        // Parse amount to Wei (assuming token has 9 decimals)
        const amountInWei = ethers.utils.parseUnits(amount, 9);
    
        // Call the transfer function with the user's address and the approved amount
        const tx = await contract.transfer(phiReceiver_address, amountInWei);
  
        // Wait for the transaction to be mined
        await tx.wait();
       
         return true;
  
      } catch (error) {
        // If there was an error with the transaction
        alert(error.reason);
        // console.error('Staking error:', error);
        return false;
      }
    } else {
      alert('BSC Network Wallet dApp not found!');
      return false;
    }
  };
  
 
  const getBalanceusdt = async (contractAddress, contractABI, address) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      try {
        const balance = await contract.balanceOf(address);
        const formattedBalance = (Math.floor(parseFloat(ethers.utils.formatUnits(balance, 18)) * 100000) / 100000).toFixed(5);
        setUsdtBalance(formattedBalance);
      } catch (error) {
        // alert(`Error fetching balance for contract ${contractAddress}`);
      }
    } else {
      alert("BSC Network Wallet dApp not found!");
    }
  };




  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      if (isConnecting) {
        alert("Connection request already in progress.");
        return;
      }

      try {
        setIsConnecting(true);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);
        localStorage.setItem('walletAddress', address);
        getBalance(address);
        getBalanceusdt(USDTContractAddress, minimalABI, address);
      } catch (error) {
        if (error.code === -32002) {
          alert('Connection request is already in progress. Please wait.');
        } else {
          alert('Error connecting');
        }
      } finally {
        setIsConnecting(false);
      }
    } else {
      alert('BSC Network Wallet dApp not found!');
    }
  };

  const disconnectWallet = () => {
    setWalletAddress('');
    setBalance('');
    setUsdtBalance('');
    localStorage.removeItem('walletAddress');
    // toast.success('Wallet Disconnected')
  };

  const getBalance = async (address) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      try {
        const balance = await contract.balanceOf(address);
        const formattedBalance = (Math.floor(parseFloat(ethers.utils.formatUnits(balance, 9)) * 100000) / 100000).toFixed(5);
        setBalance(formattedBalance); // Adjust decimals as necessary
      } catch (error) {
        // alert("Error fetching balance");
      }
    }
  };

  const getPHIrate = async () => {
    const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
    // const provider = new ethers.providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545/');
    const contract = new ethers.Contract(contractAddress, contractABI, provider);

    const Phirate = await contract.rateInUSDT();

    const liverate = Phirate.toString();
    const firstFourDigits = liverate.slice(0, 5);
    setPhiRate(firstFourDigits / 1e5);

  };

  useEffect(() => {

    getPHIrate();


    const storedAddress = localStorage.getItem('walletAddress');
    if (storedAddress) {
      setWalletAddress(storedAddress);

      const phi = getBalance(storedAddress);
      const usdt = getBalanceusdt(USDTContractAddress, minimalABI, storedAddress);


    }

  }, []);


  return (
    <>
      <WalletContext.Provider value={{ walletAddress, balance, isConnecting, phirate, usdtbalance, connectWallet, disconnectWallet, mintPHI, BurnPHI, stakeAmount, TransferUSDT, TranferPHIAmount }}>
        {children}
      </WalletContext.Provider>
      <ToastContainer />
    </>
  );
};