import React, { useState } from 'react';

const TransferPhiPopup = ({ 
    isOpen, 
    onClose, 
    usdtbalance, 
    USDTamount, 
    handleUsdtChange, 
    handleTransfer,
    walletAddress,
    connectWallet,
    copyToClipboard,
    address,
    isSubmitting,
    checkDailyLimit 
}) => {
    if (!isOpen) return null;

    const value = usdtbalance * 0.001618;
    const display_value = value/100;

    return (
        <div className="popup-overlay" style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }}>
            <div className="popup-content" style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '10px',
                width: '90%',
                maxWidth: '400px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
            }}>
                <div  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '15px'
                }}>
                    <div>
                        {/* <h2 style={{ margin: 0,textAlign:'start',color:'#cd0000c4' }}>Sell PHI</h2> */}
                        <p style={{ margin: 0, color: '#af9760',textAlign:'start' }}>Send to PHIEX</p>
                    </div>
                    <div>
                        <button 
                            onClick={onClose} 
                            style={{
                                background: '#af9760',
                                border: 'none',
                                fontSize: '24px',
                                cursor: 'pointer',
                                color: '#fff',
                                borderRadius:'10px'
                            }}
                        >
                            &times;
                        </button>
                    </div>
                </div>

                <hr style={{ margin: "10px 0", border: "0.5px solid #eee" }} />
              
                <div className="swap-body">
                    <div>
                        <label className="token-label" style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '10px'
                        }}>
                            <div className='d-flex' style={{ alignItems: 'center' }}>
                                <img
                                    src={require('../../assets/img/new_logo.png')}
                                    alt="Phi"
                                    style={{ 
                                        width: '30px', 
                                        height: '30px', 
                                        marginRight: '10px' 
                                    }}
                                />
                                PHI
                            </div>
                            <div style={{ color: 'black' }}>
                                {isNaN(parseFloat(usdtbalance)) ? "0.00" : usdtbalance}
                            </div>
                        </label>
                        <div className="swap-input2" style={{
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '10px'
                        }}>
                            <input
                                type="number"
                                style={{
                                    width: '100%',
                                    border: 'none',
                                    outline: 'none',
                                    fontSize: '16px'
                                }}
                                name='usdt'
                                value={'0'}
                                onChange={handleUsdtChange}
                                placeholder="Enter USDT amount"
                                readOnly
                            />
                        </div>
                        <div className='d-flex' style={{ alignItems: 'center' }}>
                                PHIEX Registered Email
                            </div>
                        <div className="swap-input2" style={{
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '10px'
                        }}>
                            <input
                                type="email"
                                style={{
                                    width: '100%',
                                    border: 'none',
                                    outline: 'none',
                                    fontSize: '16px'
                                }}
                                name='usdt'
                                placeholder="PHIEX Registered Email"
                    
                            />
                        </div>
                
                    </div>
                </div>

                {walletAddress ?
                    <button 
                        // onClick={handleTransfer}
                        style={{
                            width: '100%',
                            padding: '12px',
                            backgroundColor: '#af9760',
                            color: 'white',
                            border: 'none',
                            borderRadius: '8px',
                            marginTop: '15px',
                            cursor: 'pointer'
                        }}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Selling....' : 'Coming Soon'}
                        
                    </button>
                    :
                    <button 
                        onClick={connectWallet}
                        style={{
                            width: '100%',
                            padding: '12px',
                            backgroundColor: '#af9760',
                            color: 'white',
                            border: 'none',
                            borderRadius: '8px',
                            marginTop: '15px',
                            cursor: 'pointer'
                        }}
                    >
                        Connect Wallet
                    </button>
                }
           
           
                    </div>
        </div>
    );
};

export default TransferPhiPopup;