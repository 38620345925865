
import React, { useState, useContext, useEffect } from 'react';

import Header from '../Common/Header';
import Footer from '../Common/Footer';
import SwapCSS from '../../assets/css/Swap.css';
import Token from '../../assets/img/token.png';
import WalletIcon from '../../assets/img/wallet-icon.png';
import user_icon from '../../assets/img/user_icons.png';
// import Phi from '../../assets/img/phi.png';
// import USDT from '../../assets/img/usdt.png';
import Help from '../../assets/img/help.png';
// import phiBlueLogo from '../assets/img/phi_blue_logo_1.png';
import phiBlueLogo from '../../assets/img/phi_token_logo.png';
import phiNameLogoFull from '../../assets/img/phi_name_logo.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { WalletContext } from '../Common/WalletContext';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


const NewSwap = () => {
    const { walletAddress, connectWallet, balance, usdtbalance, mintPHI, phirate, BurnPHI } = useContext(WalletContext);

    const [selectedToken1, setSelectedToken1] = useState('PHI');
    const [selectedToken2, setSelectedToken2] = useState('USDT');
    const [Phiamount, setPHIAmount] = useState('');
    const [USDTamount, setUSDTAmount] = useState('');

    // Update PHI and USDT amounts based on rate
    const handlePhiChange = (e) => {
        const value = e.target.value;
        setPHIAmount(value);
        setUSDTAmount((value * phirate).toFixed(4));
    };

    const handleUsdtChange = (e) => {
        const value = e.target.value;
        setUSDTAmount(value);
        setPHIAmount((value / phirate).toFixed(4));
    };
    const copyToClipboard = () => {
        const address = process.env.REACT_APP_CONTRACT_ADDRESS;
        navigator.clipboard.writeText(address).then(() => {
            alert("Address copied!");
        }).catch((err) => {
            console.error('Failed to copy: ', err);
            alert('Failed to copy');
        });
    };

    // Tokens data with images
    const tokens = {
        PHI: require('../../assets/img/logo.png'),  // PHI icon
        USDT: require('../../assets/img/usdt.png') // USDT icon
    };

    // Handle changes in the first dropdown
    const handleToken1Change = (e) => {
        const newToken1 = e.target.value;
        const newToken2 = newToken1 === 'PHI' ? 'USDT' : 'PHI';
        setSelectedToken1(newToken1);
        setSelectedToken2(newToken2);
    };

    const handleSwapClick = () => {

        if (selectedToken1 == "PHI") {
            mintPHI(USDTamount); // Adjust recipient address and amount accordingly
        } else {
            BurnPHI(Phiamount);
        }
    };


    // Handle changes in the second dropdown
    const handleToken2Change = (e) => {
        const newToken2 = e.target.value;
        const newToken1 = newToken2 === 'PHI' ? 'USDT' : 'PHI';
        setSelectedToken2(newToken2);
        setSelectedToken1(newToken1);
    };

    const walletaddress = localStorage.getItem('walletAddress');

    const truncateToFiveDigits = (num) => {
        const numStr = num.toString();
        if (numStr.includes('.')) {
            const [integerPart, decimalPart] = numStr.split('.');
            return `${integerPart}.${decimalPart.slice(0, 5)}`;
        }
        return numStr; // If no decimal part, return the integer part as is
    };

    const handlePercentageClick = (percentage) => {
        const selectedBalance = selectedToken2 === 'PHI' ? balance : usdtbalance;
        const amount = selectedBalance * (percentage / 100); // No rounding

        if (selectedToken2 === 'PHI') {
            setPHIAmount(truncateToFiveDigits(amount));
            setUSDTAmount(truncateToFiveDigits(amount * phirate));
        } else {
            setUSDTAmount(truncateToFiveDigits(amount));
            setPHIAmount(truncateToFiveDigits(amount / phirate));
        }
    };

    const [phiPrices, setPhiPrices] = useState([]);

    useEffect(() => {
        const deploymentPrice = 0.16180;  // Initial price
        const currentPrice = phirate;     // Current live price
        const daysSinceDeployment = 10;   // Days since deployment

        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - daysSinceDeployment);

        // Helper function to format dates as "DD/MM/YYYY"
        const formatDate = (date) => {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };

        // Create array with dates and prices
        const prices = [];
        for (let i = 0; i <= daysSinceDeployment; i++) {
            const date = new Date(startDate);
            date.setDate(startDate.getDate() + i);

            // Calculate price linearly (straight line)
            const progress = i / daysSinceDeployment;
            const price = deploymentPrice + (currentPrice - deploymentPrice) * progress;

            prices.push({
                day: formatDate(date),
                price: Number(price.toFixed(5)),
                type: 'Historical'
            });
        }
        console.log(prices);
        setPhiPrices(prices);
    }, [phirate]);

    const cryptoData = [
        { rank: 1, symbol: "PHI", marketCap: "$0.00 B", price: "$0.00" },
        { rank: 2, symbol: "BTC", marketCap: "$1.95 T", price: "$98,365.00" },
        { rank: 3, symbol: "ETH", marketCap: "$407.86 B", price: "$3,386.95" },
        { rank: 4, symbol: "USDT", marketCap: "$132.79 B", price: "$1.00" },
        { rank: 5, symbol: "SOL", marketCap: "$120.03 B", price: "$252.97" },
        { rank: 6, symbol: "BNB", marketCap: "$96.08 B", price: "$658.77" },
    ];


    return (
        <>
            <Header />
            <div className="toggle-switch">

                <div className='tab d-flex justify-content-between'><span><img src={phiNameLogoFull} style={{ width: '49px', margin: '0px 2px 2px 20px' }} alt="logo" /></span><span style={{ color: '#26a17b', marginRight: '20px' }}> ${phirate}</span> </div>
            </div>
            <div className="Newswap-container">
                <div className="newSwap-card">
                    <div className='d-flex'>
                        <div><img src={Logo} alt="phi" className="newSwap-token-logo" /></div>
                        <div>
                            <p className='newSwap_para_1'>PHI</p>
                            <p className='newSwap_para_2'>100.12345 PHI/ $10.54375</p>
                        </div>
                    </div>
                    <div className='newSwapCenterHeading'><p>Deposit Withdraw</p></div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <p className='newSwapWalletAddress'>{walletAddress}</p>
                        <img src={WalletIcon} alt="Wallet Icon" className="newSwapWalletIcon" />
                    </div>

                </div>
            </div>
            {/* <div className="Newswap-container">
                <div className="newSwap-card">
                    <div>
                        <p className='newSwap_para_1'>PHI</p>
                        <div>
                            <img src={user_icon} alt="phi" className="newSwap-token-user-icon" />
                            <span>0/0</span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between mt-3'>
                        <p style={{ marginBottom: '0' }}>Buy.</p>
                        <p style={{ marginBottom: '0' }}>Withdraw.</p>
                    </div>

                </div>
            </div> */}

            <div className="crypto-table-container mb-5">
                <table className="crypto-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Market Cap</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cryptoData.map((crypto) => (
                            <tr key={crypto.rank}>
                                <td data-label="#">{crypto.rank}</td>
                                <td data-label="Market Cap">
                                    <div className="crypto-symbol">
                                        <span>{crypto.symbol}</span>
                                        <span className="market-cap">{crypto.marketCap}</span>
                                    </div>
                                </td>
                                <td data-label="Price">{crypto.price}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <Footer />
            <ToastContainer />
        </>
    );
}

export default NewSwap;
