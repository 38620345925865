import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom'; // Import useParams
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import SwapCSS from '../../assets/css/Swap.css';
import StackCSS from '../../assets/css/Stack.css';
import AuthCSS from '../../assets/css/Auth.css';
import WalletIcon from '../../assets/img/wallet-icon.png';
import { WalletContext } from '../Common/WalletContext.js';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Register = () => {
    const { walletAddress, isConnecting, connectWallet } = useContext(WalletContext);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sponcer_id, setSponcer_id] = useState(''); // Initial state for sponsor
    const [usersData, setUsersData] = useState([]);
    const [isValidSponcer, setIsValidSponcer] = useState(true); // State to track sponsor validity
    const navigate = useNavigate();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const { walletAddress: urlWalletAddress } = useParams(); // Use useParams to get walletAddress from URL

    useEffect(() => {
        // If the wallet address is available in the URL, set it as the sponsor ID
        if (urlWalletAddress) {
            setSponcer_id(urlWalletAddress);
        }
    }, [urlWalletAddress]);

    useEffect(() => {
        // Fetch all user data when component mounts
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${apiBaseUrl}/getalluser2`);
                setUsersData(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchUserData();
    }, [apiBaseUrl]);

    const handleSponcerBlur = () => {
        // Check if the entered sponsor ID exists in the fetched user data
        const validSponcer = usersData.some(user => user.email === sponcer_id);

        if (!validSponcer) {
            setIsValidSponcer(false);
            alert('Invalid Sponsor ID. Please enter a valid one.');
        } else {
            setIsValidSponcer(true); // Reset the flag if the sponsor ID is valid
            alert('Sponsor ID verified.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prevent submission if sponsor ID is invalid
        if (!isValidSponcer) {
            alert('Please enter a valid Sponsor ID before submitting.');
            return;
        }

        // Create form data to send
        const formDataToSend = {
            sponcer_id: sponcer_id,
            email: walletAddress,
        };

        try {
            const response = await axios.post(
                `${apiBaseUrl}/register`,
                formDataToSend,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            localStorage.setItem('user_ID', response.data.user.id);
            alert('Registration successful');
            navigate(-1);  // Go back to the previous page
        } catch (error) {
            // Handle any errors that occur during the API request
            console.error("Error registering:", error);
            if (error.response && error.response.status === 422) {
                alert('User is already registered.');
            } else {
                alert("Error registering: " + error.message);
            }
        }
    };

    const shortenAddress = (address) => {
        return address ? `${address.slice(0, 4)}...${address.slice(-6)}` : '';
    };

    return (
        <>
            <Header />
            <div className="container auth_main_section" style={{marginTop:'70px'}}>
                <div className="row justify-content-center ">
                    <div className="col-md-6 auth_main_sub_section">
                    <div className='d-flex justify-content-center'>
                        <div className='lock_phi_stake col-12 d-flex justify-content-center'>
                            <h3 className="text-center mt-2 mb-2">Sign Phi Network</h3>
                        </div>
                    </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="sponcer_id" className="form-label">Sponsor Address</label>
                                <input
                                    type="text"
                                    className={`form-control ${!isValidSponcer ? 'is-invalid' : ''}`}
                                    id="sponcer_id"
                                    placeholder="Enter Sponsor ID"
                                    value={sponcer_id}
                                    onChange={(e) => setSponcer_id(e.target.value)}
                                    onBlur={handleSponcerBlur} // Trigger onBlur validation
                                />
                                {!isValidSponcer && (
                                    <div className="invalid-feedback">
                                        Invalid Sponsor ID.
                                    </div>
                                )}
                            </div>

                            <label htmlFor="wallet_address" className="form-label">Your Wallet Address</label> <br />
                            {!walletAddress && (
                                <button
                                    className="register_wallet_btn"
                                    onClick={connectWallet}
                                    disabled={isConnecting}
                                >
                                    {isConnecting ? 'Connecting...' : 'Connect Wallet'}
                                </button>
                            )}
                            {walletAddress && (
                                <div className="wallet_section_register">
                                    <span className="wallet-address_register">
                                        {shortenAddress(walletAddress)}
                                    </span>
                                </div>
                            )}
                            <button type="submit" className="btn w-100 mb-3 mt-3 login_submit_btn">Sign up</button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />

            <ToastContainer />
        </>
    );
}

export default Register;
