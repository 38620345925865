import axios from 'axios';
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../assets/css/team.css';
import { WalletContext } from '../../Common/WalletContext';
import logo from '../../../assets/img/logo.png';
import LevelIncomeImg from '../../../assets/img/level_income_img.png';

// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Pagination from './react-pegination';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Direct_Team() {
  const { walletAddress } = useContext(WalletContext);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loadingRef = useRef(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const checkWalletAndFetchData = useCallback(async (walletAddress) => {
    if (!walletAddress) {
      alert('Wallet not connected. Waiting...');
      return;
    }

    try {
      const response = await axios.get(`${apiBaseUrl}/getuser/${walletAddress}`);
      const userData = response.data;
      const userID = userData?.id;

      if (!userID) {
        alert('User not found.');
        navigate('/register');
      } else {
        localStorage.setItem('user_ID', userID);
      }
    } catch (error) {
      alert(error.message);
    }
  }, [apiBaseUrl, navigate]);

  const fetchData = useCallback(async (currentPage) => {
    if (loadingRef.current || !hasMore) return;

    loadingRef.current = true;
    setLoading(true);
    try {
      const response = await axios.get(`${apiBaseUrl}/userteamapinew/${walletAddress}`, {
        params: { page: currentPage }
      });

      if (response.data) {
        setData(prevData => {
          if (prevData && prevData.user_team && prevData.user_team.data) {
            return {
              ...response.data,
              user_team: {
                ...response.data.user_team,
                data: [...prevData.user_team.data, ...response.data.user_team.data]
              }
            };
          }
          return response.data;
        });
        setPage(prevPage => prevPage + 1);
        setHasMore(response.data.user_team.data.length > 0);
      } else {
        navigate('/register');
        alert('User not registered');
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  }, [apiBaseUrl, navigate, walletAddress]);

  useEffect(() => {
    if (walletAddress) {
      checkWalletAndFetchData(walletAddress);
    }
    fetchData(1);
  }, [walletAddress, checkWalletAndFetchData, fetchData]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        if (hasMore && !loadingRef.current) {
          fetchData(page);
        }
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener('scroll', debouncedHandleScroll);
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  }, [page, hasMore, fetchData]);

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const formatDate = (dateString) => {
    const months = [
      'Jan', 'Feb', 'March', 'April', 'May', 'June',
      'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${day} ${month} ${year} | ${hours}:${minutes}:${seconds}`;
  };

  return (


    <body className=''>
      <div >


        <Header />

        <section style={{ marginTop: '65px' }}>
          <div >
            <div className="team-body-content">
              <center><h3 className='mt-2 mb-3 network_heading'>Network Business</h3></center>
              <section>
                <div className='user_address shadow'>
                  <div>
                    <div>{data && data.user && data.user.sponcer_id}</div>
                    {/* <div>0x6ea894D4B80954990af0ef0f4efB3d9A20B0a750</div> */}

                  </div>
                </div>
                <p className='sponcer_para'>Introducer</p>
              </section>
              <section>
                {/* <div className='total_business shadow'>
                  <div>
                    <img src={LevelIncomeImg} alt="Direct Income" className="portfolio-icon" />
                  </div>
                  <div>
                    <h6 className='text-black text-center'>Total Team Staking</h6>
                    <div className='text-black text-center' style={{ fontSize: '18px' }}>${data && data.user && data.user.my_business}  </div>
                  </div>

                </div> */}
                <div className='total_business_team shadow'>
                  <div className='row'>
                    <div className='col-6'>
                      <h6 className='text-black text-center'>Team</h6>
                      <div className='text-black text-center' style={{ fontSize: '18px' }}>${data && data.user && data.user.my_business}  </div>
                    </div>
                    <div className='col-6'>
                      <h6 className='text-black text-center'>Direct</h6>
                      <div className='text-black text-center' style={{ fontSize: '18px' }}>${data && data.sumMyDirectBusiness}  </div>
                    </div>
                  </div>
                </div>
              </section>

              {data && data.user_team && data.user_team.data.length > 0 ? (
                <section className='mt-2'>

                  {data.user_team.data.map((user, index) => (
                    <div key={index} className="col-12">
                      <div className="team-transaction-box shadow">
                        <div>
                          <div>

                            <div className="team-transaction-name-2 mb-2">
                              {user.email}

                            </div>
                          </div>
                          <div className='d-flex justify-content-between'>
                            <div style={{ display: 'flex', alignItems: 'center' }}><span className="report-date" style={{ fontSize: '12px', color: '#000' }}>{formatDate(user.joining_date)}</span><br /></div>
                            <div style={{ display: 'flex', alignItems: 'center' }}><h6 className='' style={{ color: '#000' }}>${user.my_package}</h6></div>

                          </div>
                        </div>
                      </div>
                      {/* <span style={{color:'black'}}>{user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)}</span> */}

                      {/* <div className="team-transaction-box shadow">
                        <a href="#" style={{ textDecoration: 'none' }} >

                  
                          <div className="">
                            <div className="align-content-center">

                              <div className="team-transaction-name-2">
                                {user.email}

                              </div>
                              
                            </div>
                          </div>
                          <div className="align-content-center">
                            <span className="align-content-center report-date">{formatDate(user.joining_date)}</span><br />
                          </div>

                          <div className="align-content-center text-center">
                            <h3 className='transaction-btn2'>{user.is_active === 'active' ? 'Active' : 'Inactive'}</h3>

                          </div>

                        </a>
                      </div> */}
                    </div>
                  ))}

                </section>
              ) : (
                <div style={{ textAlign: 'center', color: 'black' }}>No Team data available</div>
              )}


            </div>
          </div>
        </section>
        <ToastContainer />
        <Footer />
      </div>
    </body>
  );
}

export default Direct_Team;